import React from "react"
import { useEffect, useState } from "react"
import { Block } from "baseui/block"
import { Label1 } from "baseui/typography"
import CloudApi from "cloudApi/api"
import Header from "../components/molecules/Header"
import { JobDetail } from "../components/molecules"
import withJob from "hoc/withJob"
import { Spinner } from "baseui/spinner"
import { navigate } from "gatsby"

const windowGlobal = typeof window !== "undefined" && window
const { localStorage } = windowGlobal

const JobDetailPage = ({ jobState, jobDispatch, location }) => {
  const { jobs, appliedJobs } = jobState
  const [job, setJob] = useState(null)
  useEffect(() => {
    let promise = []
    if (jobs.length === 0) {
      const jobId = location.search.substring(1)
      promise.push(CloudApi.getJobDetail(jobId))
      if (localStorage.getItem("tokenId")) {
        promise.push(CloudApi.getJobsApplied())
      }
      return Promise.all(promise).then(([job, appliedJobsData]) => {
        jobDispatch({ type: "FETCH_JOBS_DETAIL", payload: job })
        if (localStorage.getItem("tokenId")) {
          jobDispatch({
            type: "FETCH_APPLIED_JOBS_DATA",
            payload: appliedJobsData,
          })
        }
        setJob(job)
      })
    } else {
      const jobId = location.search.substring(1)
      const job = jobs.find(job => job.id === jobId)
      setJob(job)
    }
  }, [])

  const getJobsApplied = () =>
    CloudApi.getJobsApplied().then(appliedJob =>
      jobDispatch({ type: "FETCH_APPLIED_JOBS_DATA", payload: appliedJob })
    )

  const getJobDetail = id =>
    CloudApi.getJobDetail(id).then(job =>
      jobDispatch({
        type: "FETCH_JOBS_DETAIL",
        payload: job,
      })
    )
  return (
    <Block>
      <Header />
      <Label1
        style={{
          color: "#fc7c2e",
          textDecoration: "underline",
          marginTop: 20,
          marginLeft: 20,
        }}
        onClick={() => navigate("/")}
      >
        {"< ย้อนกลับ"}
      </Label1>
      {job === null ? (
        <Block
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={"60%"}
        >
          <Spinner
            size={80}
            overrides={{
              ActivePath: {
                style: ({ $theme }) => ({ fill: $theme.colors.primary }),
              },
            }}
          />
        </Block>
      ) : (
        <JobDetail
          job={job}
          jobTags={job.tags}
          appliedJobs={appliedJobs}
          getJobsApplied={getJobsApplied}
          getJobDetail={getJobDetail}
        />
      )}
    </Block>
  )
}

export default withJob(JobDetailPage)
